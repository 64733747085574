import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import {
  faFacebook,
  faGoogle,
  faApple,
  faReddit,
  faDiscord,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
  faGlobe,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BCMCharacter } from '../../../modules/bcm/common/components/bcm-character';

const BCMGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_about.png"
            alt="About"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>All you need to know about Black Clover Mobile.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Black Clover Mobile: Rise of the Wizard King (also known as Black
          Clover Mobile: The Opening of Fate in the JPN region) is an turn-based
          gacha game developed by Vic Game Studios. The studio was created in
          2021 by Jae-Young Choi who was the lead developer for Seven Deadly
          Sins Grand Cross. Garena will be the Global publisher for the game.
        </p>
        <p>
          Overall the game takes a lot of inspirations from Epic Seven, but puts
          its own twist to some of the system and introduces quality of life
          improvements to make them more modern. The further sections of this
          guide go into more detail about them.
        </p>
        <SectionHeader title="Important links" />
        <p>This section showcases the most important links you need to know:</p>
        <Tabs
          defaultActiveKey="Global"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="Global" title="Global">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faFacebook} /> Facebook
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.facebook.com/bclovermobile/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/bclover_mobileg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.garena.game.bc&hl=en_US"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/ch/app/black-clover-m/id1616496847?l=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.reddit.com/r/BlackCloverMobile/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">
                      <a
                        href="https://discord.gg/bcloverm"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="KR" title="KR">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://bclover-forum.vicgame.kr/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faFacebook} /> Facebook
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.facebook.com/bclovermobilekr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/vic_sns"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vic.bc.kr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/kr/app/id6444939616"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">-</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="JPN" title="JPN">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://bclover-mobile.vicgame.jp/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/bclover_mobile"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vic.bc.jp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/jp/app/id6444940093"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">-</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <SectionHeader title="Gameplay" />
        <p>The gameplay in Black Clover Mobile is split into two modes:</p>
        <h5>Overworld</h5>
        <StaticImage
          src="../../../images/blackclover/bcm_1.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          In the overworld you can roam around the map and visit various
          locations known from the manga and anime. You can also enter some of
          them and then the view changes into third-perspective one - you can
          run around the location, find hidden chests etc.
        </p>
        <h5>Combat</h5>
        <StaticImage
          src="../../../images/blackclover/bcm_4.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          The combat is turn based, but what makes is stand out compared to most
          turn based gacha games is the <strong>partner system</strong>. Each
          character has a skill that can be used with the partner and they both
          will attack the target at the same time - what's more, if you partner
          up characters with their dedicated partner, they will use an upgraded
          version of the skill!
        </p>
        <p>
          Also, the game uses a Special Point system and some of the character
          skills can't be used if you don't have enough of them - so you can't
          just spam your strongest skills every turn.
        </p>
        <p>
          All battles in the game are 4 vs 4, which means you are able to take
          create teams with 4 characters only.
        </p>
        <SectionHeader title="Game modes" />
        <p>
          Here's the list of game modes available in the game (that we are aware
          of):
        </p>
        <h5>Story</h5>
        <ul>
          <li>
            The bread and butter of every gacha game. Progressing through the
            story will unlock the other modes and features.
          </li>
          <li>
            At the release of KR/JPN version, only the first 3 chapters were
            available, but 2 weeks after the 4 one was added.
          </li>
          <li>
            What's interesting is that to tackle the story stages you need{' '}
            <strong>Story Tickets</strong> - they recharge slowly over time,
            similar to stamina.
          </li>
        </ul>
        <h5>Normal Challenge</h5>
        <StaticImage
          src="../../../images/blackclover/generic/normal_chal.jpg"
          alt="Black Clover Mobile"
        />
        <ul>
          <li>
            The normal challenge mode contains stages where you can use your
            stamina to farm character EXP, Gear, Skill Upgrade materials etc.
          </li>
          <li>
            The stamina you use is separate from the Story Tickets, so you don't
            have to worry about saving it to progress through the game,
          </li>
          <li>
            Each stage is split into sub-stages that require higher team power
            level, but also give better rewards.
          </li>
        </ul>
        <h5>Limited Challenge</h5>
        <StaticImage
          src="../../../images/blackclover/generic/lim_chal.jpg"
          alt="Black Clover Mobile"
        />
        <ul>
          <li>
            The Limited Challenge mode contains stages that you can tackle only
            a limited number of times per day,
          </li>
          <li>The stages available here rotate every day/every week,</li>
          <li>
            Some of the stages here require you to use multiple teams, not just
            your main one,
          </li>
        </ul>
        <h5>Arena</h5>
        <StaticImage
          src="../../../images/blackclover/generic/arena.jpg"
          alt="Black Clover Mobile"
        />
        <ul>
          <li>
            A PVP mode is what most gacha games need, so you can compare
            yourself to other players,
          </li>
          <li>Only AI vs AI battles were available in the Closed Beta,</li>
          <li>
            You get weekly and seasonal rewards based on the league you've
            reached.
          </li>
        </ul>
        <SectionHeader title="Characters" />
        <StaticImage
          src="../../../images/blackclover/bcm_2.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          Around 50 characters will be available on the release of the game.
        </p>
        <h5>Rarity</h5>
        <p>
          There are 3 base rarities available in the game:{' '}
          <strong>R, SR and SSR</strong>, but any R and SR character can be
          raised to SSR (and above).
        </p>
        <h5>Types</h5>
        <p>
          Each character belongs to one of the three types - and they work like
          elements you know from other games:
        </p>
        <ul>
          <li>
            <strong className="teal">Technique</strong>
          </li>
          <li>
            <strong className="green">Sense</strong>
          </li>
          <li>
            <strong className="red">Power</strong>
          </li>
        </ul>
        <p>
          The usual paper-scissor-rock applies here, so Red beats Green, Green
          beats Blue and Blue beats Red.
        </p>
        <p>
          Currently there are no Light/Dark faction equivalents in the game.
        </p>
        <h5>Classes</h5>
        <p>
          Each character belongs to one of the five classes, each specializing
          in different style of combat:
        </p>
        <ul>
          <li>
            <strong>Attacker</strong>
          </li>
          <li>
            <strong>Defender</strong>
          </li>
          <li>
            <strong>Debuffer</strong>
          </li>
          <li>
            <strong>Healer</strong>
          </li>
          <li>
            <strong>Support</strong>
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
        <SectionHeader title="Skill Pages" />
        <StaticImage
          src="../../../images/blackclover/generic/skillcard.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          Characters can equip Skill Pages that give them additional passives
          and also upgrade one of their skills (but this works only on specific
          characters). Also keep in mind that Skill Pages have class
          restriction, so to get their full benefits, you need to match their
          class to the character's class.
        </p>
        <SectionHeader title="Gacha rates" />
        <p>Here are the gacha rates:</p>
        <h5>Rate-up banner</h5>
        <ul>
          <li>Mage - 60%</li>
          <ul>
            <li>SSR (Normal character) - 1.5%</li>
            <li>SSR (Rate-up character) - 1%</li>
            <li>SR - 14%</li>
            <li>R - 43.5%</li>
          </ul>
          <li>Skill Page - 40%</li>
          <ul>
            <li>SSR (Normal Skill Page) - 0.82%</li>
            <li>SSR (Rate-up Skill Page) - 0.68%</li>
            <li>SR - 10%</li>
            <li>R - 28.5%</li>
          </ul>
        </ul>
        <h5>Normal banner</h5>
        <ul>
          <li>Mage - 60%</li>
          <ul>
            <li>SSR (Normal character) - 2%</li>
            <li>SSR (Seasonal) - 0.5%</li>
            <li>SR - 14%</li>
            <li>R - 43.5%</li>
          </ul>
          <li>Skill Page - 40%</li>
          <ul>
            <li>SSR (Normal Skill Page) - 1.32%</li>
            <li>SSR (Seasonal) - 0.18%</li>
            <li>SR - 10%</li>
            <li>R - 28.5%</li>
          </ul>
        </ul>
        <p>
          You can learn more about Seasonal SSR characters in Gateway of Destiny
          section below.
        </p>
        <SectionHeader title="Mage Path" />
        <StaticImage
          src="../../../images/blackclover/generic/magepath.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          Mage Path is the equivalent of the beginner missions from other games.
          It basically introduces the new game features you unlock as you
          progress and rewards you with gear, summoning currency, and other
          things.
        </p>
        <SectionHeader title="Gateway of Destiny" />
        <StaticImage
          src="../../../images/blackclover/generic/gateway.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          Gateway of Destiny is a very interesting system that allows you to
          obtain one of the special seasonal characters for free. The first
          season features 3 characters: Asta, Yami and Mimosa.
        </p>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="asta-clover-academy" enablePopover />
          <BCMCharacter
            mode="icon"
            slug="mimosa-vermillion-clover-academy"
            enablePopover
          />
          <BCMCharacter
            mode="icon"
            slug="yami-sukehiro-clover-academy"
            enablePopover
          />
        </div>
        <p>
          The seasonal characters are actually really strong - they sit among
          the best characters in the game.
        </p>
        <p>
          When the season first starts, there will be a dedicated Seasonal
          banner where you will have a higher chance to get the seasonal
          characters, but after 2 weeks, once the banner ends, they will be
          added to the General Pool of character. Still, once a new season
          releases, they will be removed again and replaced by the new Seasonal
          characters.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Black Clover M | Prydwen Institute"
    description="All you need to know about Black Clover Mobile."
  />
);
